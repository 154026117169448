// @flow
import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../../components/layout';
import Image from '../../components/image';
import GlossaryLink from '../../components/glossary-link';
import type { FrontMatter, ImageType } from '../../utils/types';
import { withFrontMatter } from '../../components';
import AllImagesLink from '../../components/all-images-link';

type Props = {
  frontMatter: FrontMatter,
  data: {
    _1: ImageType,
    _2: ImageType,
    _3: ImageType,
    _4: ImageType,
    _5: ImageType,
  },
};

class Blog_2017_09_30_Dry_Canyon_Practice extends React.Component<Props> {
  render() {
    const { data, frontMatter } = this.props;

    return (
      <Layout frontMatter={frontMatter}>
        <p>
          I got to host an ACA Practice Session up Dry Canyon in Utah Valley.
        </p>
        <p>
          I don’t have many pictures of the technical stuff we practiced. I do
          have a few pictures of the fall colors, though.
        </p>
        <p>
          We met at the trailhead at 9:00 am, introduced ourselves, and then
          made the strenuous climb up to the second cliff on the left.
        </p>
        <Image
          caption="View of Utah Valley at the top of our practice cliff"
          image={data._1}
        />
        <p>
          We practiced rigging a couple of{' '}
          <GlossaryLink>natural anchors</GlossaryLink> – one{' '}
          <GlossaryLink id="wrap-3-pull-2">wrap-three-pull-two</GlossaryLink>{' '}
          from a tree and another was a{' '}
          <GlossaryLink>basket hitch</GlossaryLink> slung around a rock. We made
          sure the rigging was <GlossaryLink>EARNEST</GlossaryLink> and then set
          off down the cliff.
        </p>
        <Image
          caption={
            <GlossaryLink id="contingency-8">Figure 8 contingency</GlossaryLink>
          }
          image={data._2}
        />
        <Image
          caption={
            <>
              Heather rappelling with a{' '}
              <GlossaryLink>Valdotain Tresse</GlossaryLink>{' '}
              <GlossaryLink>self-belay</GlossaryLink>
            </>
          }
          image={data._3}
        />
        <Image
          caption={
            <>
              Scott demonstrating a great way to provide a{' '}
              <GlossaryLink>bottom belay</GlossaryLink>
            </>
          }
          image={data._4}
        />
        <p>
          Aside from practicing rigging anchors, we practiced tying the
          Valdotain Tresse, locking off while on rappel, passing a knot on
          rappel using a Valdotain Tresse, ascending and conversion to rappel,
          and lowering someone from a figure 8 contingency.
        </p>
        <Image
          caption="Looking up canyon at the next set of cliffs"
          image={data._5}
        />
        <p>
          After the designated 3 hours I had to leave. Everyone else stuck
          around and practiced some more, though. It was great to see everyone’s
          passion for doing this stuff. We were safe, happy, learned a few
          things, and I met some amazing canyoneers this morning.
        </p>
        <AllImagesLink id={frontMatter.id} />
      </Layout>
    );
  }
}

export default withFrontMatter('2017-09-30-dry-canyon-practice')(
  Blog_2017_09_30_Dry_Canyon_Practice
);

export const query = graphql`
  query {
    _1: file(
      relativePath: { eq: "images/blog/2017-09-30-dry-canyon-practice/1.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _2: file(
      relativePath: { eq: "images/blog/2017-09-30-dry-canyon-practice/2.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _3: file(
      relativePath: { eq: "images/blog/2017-09-30-dry-canyon-practice/3.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _4: file(
      relativePath: { eq: "images/blog/2017-09-30-dry-canyon-practice/4.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _5: file(
      relativePath: { eq: "images/blog/2017-09-30-dry-canyon-practice/5.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
